@import '@styles/mixins.style';

.acf-takeout-section {
  position: relative;
  padding-bottom: var(--ra-spacing-6);

  @include breakpoint(md) {
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-primary);
      mask: url('/assets/icons/takeout-line.svg');
      mask-position: center;
      mask-size: contain;
      mask-repeat: repeat-x;
    }
  }

  &__cards {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--ra-spacing-5);

    @include breakpoint(sm) {
      grid-template-columns: repeat(3, 1fr);

      > :nth-child(even) {
        top: var(--ra-spacing-6);
      }
    }
  }
}
